import React from 'react';
import { Flex, Box, Text, Heading, Image } from '@chakra-ui/core';
import Helmet from 'react-helmet';

import { colors } from '@theme';
import Layout from '../components/layout';
import ContentContainer from '../components/ContentContainer';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';

const DiversityHelmet = () => {
  return (
    <Helmet>
      <title>Diversity at Zeta: Fostering equality for our community</title>
      <link rel="canonical" href="https://www.askzeta.com/diversity" />
      <meta
        name="description"
        content="Discover how Zeta fosters diversity and inclusion for our team and 
      our broader community."
      />
      <meta
        name="og:description"
        content="Discover how Zeta fosters diversity and inclusion for our team and our broader community."
      />
    </Helmet>
  );
};

const Diversity = () => {
  return (
    <>
      <DiversityHelmet />
      <Layout noRootContainer noGetStarted mode={'home'} newNav>
        <Box pt={[0, '6rem']} />
        <Flex className="main" pt={['1rem', '6rem']} direction="column">
          <ContentContainer>
            <Flex
              pl={[4, 0]}
              pr={[6, 0]}
              className="Heading"
              direction="column"
              mb={[10, 20]}
            >
              <Flex direction="column">
                <Text
                  align={['center', 'left']}
                  style={{
                    color: colors.primary,
                    fontSize: ['20px', '18px'],
                    fontWeight: [700, 600],
                  }}
                  as="h1"
                >
                  ZETA’S DIVERSITY MANIFESTO
                </Text>

                <MobileOnly>
                  <Text
                    fontWeight={700}
                    fontSize={['24px', '40px']}
                    mt={[6]}
                    lineHeight={1.25}
                  >
                    In our mission to serve{' '}
                    <span style={{ fontWeight: 400, fontStyle: 'italic' }}>all</span>{' '}
                    <br />
                    American families, we’re committed to diversity across Zeta.
                  </Text>
                </MobileOnly>
              </Flex>
              <DesktopOnly>
                <Text
                  pl={[4, 0]}
                  pr={[6, 0]}
                  fontWeight={700}
                  fontSize={['24px', '40px']}
                  mt={[6]}
                  noOfLines={[4, 3]}
                  lineHeight={1.25}
                >
                  In our mission to serve{' '}
                  <span style={{ fontWeight: 400, fontStyle: 'italic' }}>all</span>{' '}
                  American families,
                  <br />
                  we’re committed to diversity across Zeta.
                </Text>
              </DesktopOnly>
            </Flex>
          </ContentContainer>

          <Flex direction="column" bg={colors.background} mt={4} className="commitment">
            <ContentContainer>
              <Flex ml={[4, 0]} pt={['4rem', '6rem']} direction="row">
                <Image
                  mt={[2, 1]}
                  boxSize={['22px', '35px']}
                  alt="green tab icon"
                  src="https://d142whjg6dsdg2.cloudfront.net/diversity/greentab.svg"
                />
                <Heading fontSize={['24px', '40px']} ml={[2, 4]} mr={[4, 0]}>
                  Our Commitment
                </Heading>
              </Flex>
              <Flex
                direction="column"
                pt={[4, 10]}
                fontSize={['16px', '18px']}
                maxWidth="50rem"
              >
                <Text pl={[4, '3.5rem']} pr={[6, 0]}>
                  In our mission to serve all American families, we’re making a commitment
                  to integrate diversity into every level of our company. People power
                  impact, and we want our team to be reflective of the families we aim to
                  serve. This focus on diversity will help us build a more impactful and
                  inclusive business that better serves the needs of American families.
                </Text>
              </Flex>
            </ContentContainer>
          </Flex>

          <Flex pl={[0, '6rem']} direction="column" bg={colors.background}>
            <ContentContainer>
              <Flex>
                <Heading ml={[4, 0]} fontSize={['24px', '40px']} pt={['3rem', '3rem']}>
                  Our Goal
                </Heading>
              </Flex>
              <Text
                pt={4}
                pl={[4, 2]}
                lineHeight="25px"
                fontSize={['16px', '18px']}
                fontWeight={600}
                width={['20rem', 'auto']}
              >
                Zeta should match the diversity of the US population.
              </Text>
              <Text pt={4} pl={[4, 2]} pr={[6, 0]} fontSize={['16px', '18px']}>
                We have chosen to focus our efforts on three demographic areas. Our
                benchmarks were determined according to the last available{' '}
                <a
                  target="_blank"
                  style={styles.link}
                  href="https://www.census.gov/quickfacts/fact/table/US/PST045219"
                >
                  US Census
                </a>{' '}
                (2023) and a{' '}
                <a
                  target="_blank"
                  style={styles.link}
                  href="https://news.gallup.com/poll/329708/lgbt-identification-rises-latest-estimate.aspx"
                >
                  Gallup Poll
                </a>{' '}
                (2021):
              </Text>
              <Flex
                ml={[4, 0]}
                pl={'2rem'}
                pt={('1rem', '2rem')}
                fontSize={['16px', '18px']}
              >
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>
                    <span style={styles.bold}>Race:</span> 44% non-white persons
                  </li>
                  <li style={{ paddingTop: '1.5rem' }}>
                    <span style={styles.bold}>Gender:</span> 50% women and non-binary
                  </li>
                  <li style={{ paddingTop: '1.5rem' }}>
                    <span style={styles.bold}>Sexual Orientation:</span> 6% LGBTQ+
                  </li>
                </ul>
              </Flex>
              <Text pt="2rem" pl={[4, 2]} pr={[6, 0]} fontSize={['16px', '18px']}>
                <span style={{ fontStyle: 'italic' }}>
                  Why race, gender and sexual orientation?
                </span>
                &nbsp; These three demographics reflect a broad array of money management
                possibilities, including who identifies as the CFO in a relationship, the
                way money might be handled due to legal or cultural reasons and the hidden
                implications of each group’s socio-economic diversity. Over time, we aim
                to expand our definition of diversity so we can get more sophisticated in
                how we measure it, possibly including education, geography, disability and
                religion.
              </Text>

              <Flex direction="column" pl={[4, 0]}>
                <Heading pt={['2rem', '3rem']} fontSize={['24px', '40px']}>
                  What does that mean in practice?
                </Heading>
                <Text
                  pt={4}
                  pl={[0, 2]}
                  lineHeight="25px"
                  fontWeight={600}
                  fontSize={['16px', '18px']}
                  width={['20rem', 'auto']}
                >
                  Zeta will aim to match these demographics across every aspect of our
                  business.
                </Text>
              </Flex>

              <Box
                fontSize={['16px', '18px']}
                pl={['2.5rem', '3rem']}
                pt={['2rem', '3rem']}
              >
                <ul>
                  <li>
                    <Text pr={[6, 0]}>
                      <span style={styles.bold}>Our CapTable</span> - We’d like to see our
                      investors come from diverse backgrounds. We measure this based on
                      the total number of investors we have compared to the subset who met
                      each of our demographic criteria. Investors are defined as a line on
                      our CapTable and anyone represented in a special-purpose vehicle.
                      Couples who co-own their ownership in Zeta may meet our diversity
                      criteria based on one person’s background.
                    </Text>
                  </li>
                  <li style={{ paddingTop: '1.5rem' }}>
                    <Text pr={[6, 0]}>
                      <span style={styles.bold}>Our employees</span> - We’d like to see
                      our employees come from diverse backgrounds. We measure this based
                      on the total number of employees and how they choose to identify
                      during their onboarding process.
                    </Text>
                  </li>
                  <li style={{ paddingTop: '1.5rem' }}>
                    <Text pr={[6, 0]}>
                      <span style={styles.bold}>Our community</span> - We’d like to see
                      our community come from diverse backgrounds. We’ll start by
                      measuring this based on the total number of active Customer Advisory
                      Board members and how they choose to identify during their
                      onboarding process. This is our starting point, but our goal is to
                      expand this measurement across the broader community.
                    </Text>
                  </li>
                </ul>
              </Box>
              <Flex>
                <Heading pl={[4, 0]} pt={['3rem', '3rem']} fontSize={['24px', '40px']}>
                  How will we hold ourselves accountable?
                </Heading>
              </Flex>
              <Text
                mb={['4rem', '8rem']}
                pt="1rem"
                fontSize={['16px', '18px']}
                color={colors.primary}
                pl={[4, 2]}
                pr={[6, 0]}
              >
                In the spirit of “what gets measured, gets improved,” we’ll assess
                ourselves against these metrics quarterly and publish them both internally
                during our quarterly report out and externally within this manifesto.
                <br />
                <br />
                We want to thank several individuals and organizations who have helped us
                craft this manifesto through thought leadership and advice including
                Charles Hudson at{' '}
                <a
                  target="_blank"
                  style={{ color: colors.greenPea }}
                  href="https://precursorvc.com/"
                >
                  Precursor
                </a>
                , Rohini Pandhi at{' '}
                <a
                  target="_blank"
                  style={{ color: colors.greenPea }}
                  href="https://www.transparentcollective.com/"
                >
                  Transparent Collective
                </a>
                ,{' '}
                <a
                  target="_blank"
                  style={{ color: colors.greenPea }}
                  href="https://cowboy.docsend.com/view/ky2dm5xzqtqr22tj"
                >
                  Cowboy Ventures
                </a>
                , and{' '}
                <a
                  target="_blank"
                  style={{ color: colors.greenPea }}
                  href="https://slack.com/blog/news/diversity-at-slack-2021"
                >
                  Slack
                </a>
                .
              </Text>
            </ContentContainer>
          </Flex>

          <Flex
            direction="column"
            bg={colors.diversityBlue}
            className="snapshot"
            pb={['4rem', '6rem']}
          >
            <ContentContainer>
              <Flex ml={[4, 0]} pt={['3rem', '4rem']} direction="row">
                <Image
                  mt={[2, 1]}
                  boxSize={['22px', '35px']}
                  alt="yellow tab icon"
                  src="https://d142whjg6dsdg2.cloudfront.net/diversity/yellowtab.svg"
                />
                <Heading ml={[2, 4]} mr={[4, 0]} fontSize={['24px', '40px']}>
                  Q4 2023 Snapshot
                </Heading>
              </Flex>
              <Flex pl={[4, '3.5rem']} direction="column" pt={['1rem', '2rem']}>
                <Flex direction="column">
                  <Text
                    pt={['1rem', '2rem']}
                    fontWeight={600}
                    fontSize={['18px', '24px']}
                  >
                    CapTable*
                  </Text>
                  <Text
                    fontStyle="italic"
                    pb={['1rem', '1rem']}
                    fontSize={['10px', '12px']}
                  >
                    *91% responded
                  </Text>
                  <Text pb={['1rem', '2rem']} fontSize={['16px', '18px']}>
                    47% Non-white persons
                    <br />
                    67% Women and non-binary
                    <br />
                    4% LGBTQ+
                    <br />
                    4% Prefer not to answer
                    <br />
                    --------
                    <br />
                    82% Either women or non-binary/non-white persons or LGBTQ+
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text pt="2rem" fontWeight={600} fontSize={['18px', '24px']}>
                    Employees**
                  </Text>
                  <Text
                    fontStyle="italic"
                    pb={['1rem', '1rem']}
                    fontSize={['10px', '12px']}
                  >
                    **100% responded
                  </Text>
                  <Text pb={['1rem', '2rem']} fontSize={['16px', '18px']}>
                    50% Non-white persons
                    <br />
                    58% Women and non-binary
                    <br />
                    17% LGBTQ+
                    <br />
                    0% Prefer not to answer
                    <br />
                    --------
                    <br />
                    67% Either women or non-binary/non-white persons or LGBTQ+
                  </Text>
                </Flex>
                <Flex direction="column">
                  <Text pt="2rem" fontWeight={600} fontSize={['18px', '24px']}>
                    Customer Advisory Board***
                  </Text>
                  <Text
                    fontStyle="italic"
                    pb={['1rem', '1rem']}
                    fontSize={['10px', '12px']}
                  >
                    ***42% responding
                  </Text>
                  <Text fontSize={['16px', '18px']}>
                    20% Non-white persons
                    <br />
                    22% Women and non-binary
                    <br />
                    24% LGBTQ+
                    <br />
                    0% Prefer not to answer
                    <br />
                    --------
                    <br />
                    38% Either women or non-binary/non-white persons or LGBTQ+
                  </Text>
                </Flex>
              </Flex>
            </ContentContainer>
          </Flex>

          <Flex
            pl={[0, '6rem']}
            direction="column"
            bg={colors.yellowLight}
            className="join-us"
          >
            <ContentContainer>
              <Flex align="center" pt="4rem">
                <Heading ml={[4, 0]} fontSize={['24px', '40px']}>
                  Want to join our team?
                </Heading>
              </Flex>

              <Flex ml={[4, 0]} align="center" pt="1.5rem" pb="3rem">
                <Text fontSize={['18px', '24px']}>
                  We're hiring! Learn more about our open roles{' '}
                  <a target="_blank" style={{ color: colors.greenPea }} href="/careers/">
                    here
                  </a>
                  .
                </Text>
              </Flex>
            </ContentContainer>
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};

const styles = {
  link: {
    color: colors.greenPea,
    textDecoration: 'underline',
  },
  heading: {
    fontSize: ['28px', '40px'],
  },
  bold: {
    fontWeight: 600,
  },
  text: {
    fontSize: ['16px', '18px'],
  },
};

export default Diversity;
